import Helmet from 'react-helmet';

export default function Blog2Tags() {
  return (

    <Helmet
      // htmlAttributes={{
      //   lang,
      // }}
      title={"Your Guide To Flying To Costa Rica With Your Pet"}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          property: `og:title`,
          content: 'Your Guide To Flying To Costa Rica With Your Pet'
        },
        {
          property: `og:description`,
          content: 'Pets are no exception to this, the moving of animals comes with travel and importation rules and regulations that you should know if you wish to have a pleasant',
        },
        {
          property: `og:url`,
          content: `https://www.petsgoprivateair.com/your-guide-to-flying-to-costa-rica-with-your-pet`,
        },
        {
          property: `og:image`,
          content: `./blog_thumb02.jpg`,
        },
        {
          property: `og:type`,
          content: `article`,
        },
        {
          name: `og:site_name`,
          content: "Pets Go Private Air",
        },
        {
          name: `twitter:creator`,
          content: "PGPA Mascot",
        },
        {
          name: `twitter:title`,
          content: "Your Guide To Flying To Costa Rica With Your Pet",
        },
        {
          name: `twitter:image`,
          content: `/blog_thumb02.jpg`,
        },
        {
          name: `twitter:description`,
          content: 'Pets are no exception to this, the moving of animals comes with travel and importation rules and regulations that you should know if you wish to have a pleasant',
        },
      ]
        // .concat(meta)
      }
    />
  );
};