import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import GetEstimate from './GetEstimate';
import $ from 'jquery';

function HeaderTwo() {

  // const handleActive = (e) => {
  //   document.querySelectorAll('.main-menu ul li').forEach(el => {
  //     el.classList.remove('active');
  //   })
  //   e.target.parentNode.classList += ' active';
  // }

  // const subActive = (e) => {
  //   document.querySelectorAll('.main-menu ul li').forEach(el => {
  //     el.classList.remove('active');
  //   })
  //   e.target.parentNode.parentNode.parentNode.classList += ' active';
  // }

  useEffect(() => {

    if ($('.menu-area li.menu-item-has-children ul').length) {
      $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');

    }
    if ($('.mobile-menu').length) {

      var mobileMenuContent = $('.menu-area .main-menu').html();
      $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

      $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
        $(this).toggleClass('open');
        $(this).prev('ul').slideToggle(500);
      });

      $('.mobile-nav-toggler').on('click', function () {
        $('body').addClass('mobile-menu-visible');
      });

      $('.menu-backdrop, .mobile-menu .close-btn').on('click', function () {
        $('body').removeClass('mobile-menu-visible');
      });
    }

    $(".navbar-toggle").on('click', function () {
      $(".navbar-nav").addClass("mobile_menu");
    });
    $(".navbar-nav li a").on('click', function () {
      $(".navbar-collapse").removeClass("show");
    });

    $(".header-search > a").on('click', function () {
      $(".search-popup-wrap").slideToggle();
      return false;
    });

    $(".search-close").on('click', function () {
      $(".search-popup-wrap").slideUp(500);
    });

    $(".book-button > a").on('click', function () {
      $(".search-popup-wrap").slideToggle();
      return false;
    });
  }, [])

  return (
    <header>

      {/* <div className="header-top-area">
        <div className="container custom-container">
          <div className="row align-items-center">

            <div className="col-md-7">
              <div className="header-top-left">
                <ul>
                  <li className="follow">Follow :</li>
                  <li><a href="https://www.facebook.com/groups/petstocostarica" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a></li>
                  <li><a href="https://www.instagram.com/petsgoprivate" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a></li>
                  <li><a href="https://www.youtube.com/channel/UCvasmZ2hXOit8OTTKvrB8Ng" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube" /></a></li>
                </ul>
              </div>
            </div>

            <div className="col-md-5">
              <div className="header-top-right">
                <ul className="header-top-social">

                  <li><h5 className="title"><a href="tel:9723626201"> Call Us: 972-362-6201</a></h5></li>

                </ul>
              </div>
            </div>

          </div>
        </div>
      </div> */}

      <div id="sticky-header" className="menu-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler"><i className="fas fa-bars" /></div>
              <div className="menu-wrap">
                <nav className="menu-nav show">
                  <div className="logo"><Link to="/" smooth="true"><img src="img/logo/logo.png" alt="" /></Link></div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li><Link to="/" smooth="true">Home</Link></li>
                      <li><HashLink to="/#about" smooth="true" >About</HashLink></li>
                      <li><HashLink to="/#gallery" smooth="true">Gallery</HashLink></li>
                      <li><HashLink to="/#faqs" smooth="true">FAQs</HashLink></li>
                      <li><HashLink to="/#vision" smooth="true">Why We Do It</HashLink></li>
                      {/* <li><HashLink to="/#flights" smooth="true">Upcoming Flights</HashLink></li> */}
                      <li><HashLink to="/blog" smooth="true">Blog</HashLink></li>
                      <li><HashLink to="/#contact" smooth="true">Contact</HashLink></li>
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul>
                      {/* <li className="header-search"><a href="/#"><i className="flaticon-search" /></a></li> */}
                      {/* <li className="book-button"><a href="/#"><i className="flaticon-search" /></a></li> */}
                      <li className="header-btn btn book-button"><a href="/#" style={{ color: "#ffffff" }}> Inquire</a> <img src="img/icon/w_pawprint.png" alt="" /></li>
                      <li><a href="https://www.facebook.com/groups/petstocostarica" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a></li>
                      {/* <li><a href="/#"><i className="fab fa-twitter" /></a></li> */}
                      <li><a href="https://www.instagram.com/petsgoprivate" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a></li>
                      <li><a href="https://www.youtube.com/channel/UCvasmZ2hXOit8OTTKvrB8Ng" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube" /></a></li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn"><i className="fas fa-times" /></div>
                  <div className="nav-logo"><Link to="/"><img src="img/logo/logo.png" alt="" title='true' /></Link>
                  </div>
                  <div className="menu-outer">

                  </div>
                  <div>
                    <li className="header-btn book-button btn"><a style={{ color: "#ffffff" }} href="/#"> Inquire</a> <img src="img/icon/w_pawprint.png" alt="" /></li>
                  </div>

                  <div className="social-links">
                    <ul className="clearfix">
                      <li><a href="https://www.facebook.com/groups/petstocostarica" target="_blank" rel="noopener noreferrer"><span className="fab fa-facebook-square" /></a></li>
                      <li><a href="https://www.instagram.com/petsgoprivate" target="_blank" rel="noopener noreferrer"><span className="fab fa-instagram" /></a></li>
                      <li><a href="https://www.youtube.com/channel/UCvasmZ2hXOit8OTTKvrB8Ng" target="_blank" rel="noopener noreferrer"><span className="fab fa-youtube" /></a></li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
            </div>
          </div>
        </div>
        <div className="header-shape" style={{ backgroundImage: "url('img/bg/header_shape.png')" }} />
      </div>

      <div className="search-popup-wrap" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="search-close close-btn">
          <span><i className="fas fa-times" /></span>
        </div>
        <div className="search-wrap text-center">
          <GetEstimate />
        </div>
      </div>

    </header>
  )
}

export default HeaderTwo