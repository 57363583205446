import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import HeaderTwo from './components/HeaderTwo';
import Footer from './components/Footer';
import './App.css';
import Home from './pages/Home';
import { ToastContainer } from 'react-toastify';
import PaymentsPage from './pages/PaymentsPage';

import BlogPage from './pages/BlogPage';
import Blog1 from './pages/blogs/Blog1';
import Blog2 from './pages/blogs/Blog2';


import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

const routes = [
  {
    path: '/',
    exact: true,
    header: () => <Header />,
    main: () => <Home />
  },
  {
    path: '/payments',
    exact: true,
    header: () => <HeaderTwo />,
    main: () => <PaymentsPage />
  },

  {
    path: '/blog',
    exact: true,
    header: () => <HeaderTwo />,
    main: () => <BlogPage />
  },

  {
    path: '/pet-travel-from-the-us--costa-rica',
    exact: true,
    header: () => <HeaderTwo />,
    main: () => <Blog1 />
  },
  {
    path: '/your-guide-to-flying-to-costa-rica-with-your-pet',
    exact: true,
    header: () => <HeaderTwo />,
    main: () => <Blog2 />
  },

];


class App extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
      firstname: '',
      lastname: ''
    };
  }

  render() {
    return (
      <React.Fragment>
        <Router
          history={history}
        >
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.header />}
              />
            ))}
          </Switch>

          {/* DYNAMIC COMPONENT GOES HERE */}
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}
          </Switch>

          <Footer />
        </Router>

        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          dark
        />

      </React.Fragment>
    );
  }
}

App.propTypes = {};
export default App
