import React from 'react';
import ContactAddress from './ContactAddress';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SERVICE_ID, TEMP_ID_CONTACT_FORM, USER_ID } from '../../constants/MailConst';

const customId = "contactNotify";

const contactNotify = () => {
  toast("Thank you for your enquiry. We'll be in touch with you shortly!", {
    toastId: customId
  });
}

function sendNow(e) {

  e.preventDefault();
  emailjs.sendForm(SERVICE_ID, TEMP_ID_CONTACT_FORM, e.target, USER_ID)
  // emailjs.sendForm('service_p6foln6', 'template_p0ku4ak', e.target, 'bPaHIR_EkUWZNksYl')
    .then((result) => {
      console.log(result.text);
      contactNotify();
    }, (error) => {
      console.log(error.text);
    });
  e.target.reset();
}

function ContactForm() {

  return (

    <section className="contact-area pb-110">
      <div className="container">
        <div className="container-inner-wrap">
          <div className="row justify-content-center justify-content-lg-between">
            <div className="col-lg-6 col-md-8 order-2 order-lg-0">
              <div className="contact-title mb-20">
                {/* <h5 className="sub-title">Contact Us</h5> */}
                {/* <h2 className="title">Ask Us Anything<span>.</span></h2> */}
                <h2 className="title">Contact Us</h2>
              </div>
              <div className="contact-wrap-content">
                <p>Leave us a message below and our team will get in touch with you!!</p>

                <form className="contact-form"
                  //  ref={form} 
                  onSubmit={sendNow}>
                  <div className="form-grp">
                    <label htmlFor="name">Your Name <span>*</span></label>
                    <input type="text" id="user_name" name="user_name" placeholder="Name" required />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="email">Your Email <span>*</span></label>
                    <input type="text" id="user_email" name="user_email" placeholder="email@address.com" required />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="message">Your Message <span>*</span></label>
                    <textarea name="message" id="message" placeholder="Enter your message here..." defaultValue={""} required />
                  </div>

                  {/* <div className="form-grp checkbox-grp">
                    <input type="checkbox" id="checkbox" />
                    <label htmlFor="checkbox">Don’t show your email address</label>
                  </div> */}

                  <button type="button submit" className="btn rounded-btn" value="Send"
                    // onClick={contactNotify}
                  >Send Now</button>

                </form>
              </div>
            </div>

            <ContactAddress />

          </div>
        </div>
      </div>

    </section>
  )
}

export default ContactForm;