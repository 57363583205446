// import React from 'react';
// import ReactDOM from 'react-dom';
// import $ from 'jquery';

// import App from './App';


// ReactDOM.render(
//   <App />,
//   document.getElementById('root'),
//   function(){
//     $('#preloader').delay(0).fadeOut();
//   }
// );


import React from 'react';
import { hydrate, render } from 'react-dom';
// import $ from 'jquery';

import App from './App';


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />,
    // function () {
    //   $('#preloader').delay(0).fadeOut();
    // },
    rootElement);
}
else {
  render(<App />, 
  // function () {
  //   $('#preloader').delay(0).fadeOut();
  // },
    rootElement);
}


