import React from 'react';
import HomeTwoSlider from '../components/hometwo/HomeTwoSlider';
import AdoptionFaq from '../components/adoptions/AdoptionFaq';
import Intro from '../components/info/Intro';
import InfoGallery from '../components/info/InfoGallery';
import ContactPage from '../pages/ContactPage';

import GallerySlider from '../components/GallerySlider';

function HometwoPage() {

	return (
		<main>
			<HomeTwoSlider />

			<InfoGallery />

			<GallerySlider />

			<AdoptionFaq afterElment="faq-area" />

			<Intro />

			<ContactPage />
		</main>
	)
}

export default HometwoPage;