import React from 'react';
import { Link } from 'react-router-dom'

function RecentPosts() {
  return (
    <div className="widget">
      <h4 className="sidebar-title">Recent Posts</h4>
      <div className="rc-post-list">
        <ul>
          <li>
            <div className="rc-post-thumb">
              <Link to="/pet-travel-from-the-us--costa-rica"><img src="img/blog/rc_post_thumb01.jpg" alt="" /></Link>
            </div>
            <div className="rc-post-content">
              <h5 className="title"><Link to="/pet-travel-from-the-us--costa-rica">The Future of Travel with Pets</Link></h5>
              <div className="rc-post-meta">
                <ul>
                  <li><i className="far fa-calendar-alt" /> June 8, 2022</li>
                  <li>By PGPA Mascot</li>
                </ul>
              </div>
            </div>
          </li>


          <li>
            <div className="rc-post-thumb">
              <Link to="/your-guide-to-flying-to-costa-rica-with-your-pet"><img src="img/blog/rc_post_thumb02.jpg" alt="" /></Link>
            </div>
            <div className="rc-post-content">
              <h5 className="title"><Link to="/your-guide-to-flying-to-costa-rica-with-your-pet">Your Guide to Flying to Costa Rica With Your Pet</Link></h5>
              <div className="rc-post-meta">
                <ul>
                  <li><i className="far fa-calendar-alt" /> June 8, 2022</li>
                  <li>By PGPA Mascot</li>
                </ul>
              </div>
            </div>
          </li>

          {/* <li>
          <div className="rc-post-thumb">
            <Link to="/pet-travel-from-the-us--costa-rica"><img src="img/blog/rc_post_thumb02.jpg" alt="" /></Link>
          </div>
          <div className="rc-post-content">
            <h5 className="title"><Link to="/pet-travel-from-the-us--costa-rica">Pet Needs Special Food like Human Foods</Link></h5>
            <div className="rc-post-meta">
              <ul>
                <li><i className="far fa-calendar-alt" /> June 7, 2022</li>
                <li>By PGPA Mascot</li>
              </ul>
            </div>
          </div>
        </li> */}


        </ul>
      </div>
    </div>
  )
}

export default RecentPosts;
