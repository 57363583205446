import React from 'react';
// import { Link } from 'react-router-dom';
import DownloadGuide from '../../../components/blogs/DownloadGuide';
import RecentPosts from '../../../components/blogs/RecentPosts';

function Blog1Post() {

  return (
    <section className="blog-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="standard-blog-item mb-50">
              <div className="standard-blog-thumb">
                <img src="img/blog/blog_thumb01.jpg" alt="" />
              </div>
              <div className="standard-blog-content blog-details-content">


              <div className="blog-post-meta">
                  <ul>
                    <li><i className="far fa-user" />PGPA Mascot</li>
                    <li><i className="far fa-bell" /> Jun 9, 2022</li>
                    </ul>
                </div>


                <h4 className="title">Pet Travel From the US to Costa Rica</h4>
                <p>Traveling the world is always a joy, traveling to beautiful  <a href="https://www.visitcostarica.com/en" target="_blank" rel="noopener noreferrer"> Costa Rica</a> is better and nothing beats making
                  that journey with your beloved pet. This should be as easy as that, booking a flight and going on vacation,
                  but traveling rules, regulations and laws will make traveling with your best non-human friend quite the challenge. </p>

                <blockquote>
                  "Problems are not stop signs, they are guidelines.”
                  <footer> Robert H. Schuller.</footer>
                </blockquote>

                <p>We have come up with a sure solution to your pet traveling problem. <a href="https://www.petsgoprivateair.com/" target="_blank" rel="noopener noreferrer"> Pets Go Private</a> offers international
                  standard, first-class flights for you and your pet(s). This means you and your pets can travel from
                  the United States to Costa Rica in comfort without worrying about being separated. </p>


                <div className="blog-details-img">
                  <div className="row">
                    <div className="col-12">
                      <img src="img/blog/costa.jpg" alt="" />
                    </div>
                    {/* <div className="col-md-6">
                        <img src="img/blog/blog_details_img02.jpg" alt="" />
                      </div> */}
                  </div>
                </div>

                <p>While we can provide travel for you and your pet in luxurious comfort, here are a few things you need to know about
                  traveling with your pet to the beautiful Central American country of Costa Rica. </p>


                <h4>Moving Pets to Costa Rica? What You Need to Know.</h4>
                <p>A man’s best friend can be anything from a beautiful dog to a majestic cat. With this in mind,
                  we have put together a few things you should know before booking your flight.
                  With these few pointers, your travel arrangements should go without a snag. </p>

                <h5>Requirements for DOGS entering Costa Rica:</h5>
                <p>
                  There are a few rules and regulations that you need to know and follow if you want your furry friends to enter Costa Rica.
                </p>
                <div className="blog-details-list">
                  <ul>
                    <li>Your dog must have a Rabies dosage that is at least 30 days old but less than 3 years.</li>
                    <li>Your dog’s DHLP (Distemper/Hepatitis/Leptospirosis/Parvo-virus) MUST be current.</li>
                    <li>Your dogs, Ecto and Endo worming treatment, must be administered within 15 days of the flight. </li>
                  </ul>
                </div>



                <h5>Requirements for CATS entering Costa Rica:</h5>
                <div className="blog-details-list">
                  <ul>
                    <li>Your cat must have a Rabies dosage that is at least 30 days old but less than 3 years.</li>
                    <li>Your cat’s Ecto and Endo worming treatment must be given within 15 days of the trip.</li>
                    <li>Your cat’s FVRCP (Feline Viral Rhinotracheitis, Calicivirus, Panleukopenia and Feline Leukemia) must be current.</li>
                  </ul>
                </div>

                <h5>Requirements for pet owners entering Costa Rica</h5>
                <p>
                  As a pet owner, you need to make sure your pet has all the above items and any additional requirements as stipulated
                  by the United States before your travel. This will make sure you and your pet are safe and enjoy your trip.
                  As a pet owner, here is what you need to know before traveling to Costa Rica:
                </p>
                <div className="blog-details-list">
                  <ul>
                    <li>You must have an International Health Certificate, endorsed by
                      the <a href="https://www.aphis.usda.gov/aphis/home/" target="_blank" rel="noopener noreferrer"> governing agency of your country at the federal level.</a></li>
                    <li>Ensure you have a permit to move around with your pet in the country.</li>
                  </ul>
                </div>


                <h5>Before you go…</h5>
                <p>
                  Traveling to Costa Rica with your pet can be made easier when you plan
                  with <a href="https://petsgoprivateair.com/"> Pets Go Private</a>.
                  You can tell us the holiday you desire in Costa Rica and we will create a plan so you and your
                  pet can create lasting memories.
                </p>

                <div className="blog-line" data-background="img/blog/blog_item_line.png" />


                {/* <div className="blog-details-bottom">
                  <div className="blog-details-tags">
                    <ul>
                      <li className="title"><i className="fas fa-tags" /> Tags :</li>
                      <li><a href="/#">Business</a></li>
                      <li><a href="/#">Work</a></li>
                      <li><a href="/#">Knowledge</a></li>
                      <li><a href="/#">Online</a></li>
                    </ul>
                  </div>
                  
                  
                  <div className="blog-details-social">
                    <ul>
                      <li><a href="/#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="/#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="/#"><i className="fab fa-linkedin-in" /></a></li>
                    </ul>
                  </div>
                </div> */}


              </div>


            </div>
            <div className="avatar-post mt-50 mb-50">
              <div className="post-avatar-img">
                <img src="img/blog/mascot.png" alt="img" />
              </div>
              <div className="post-avatar-content">
                <h5>By PGPA Mascot</h5>
                <p>Pet flights specialist focused on the comfort and safety of your beloved pets during travel.</p>
                {/* <div className="blog-details-social">
                  <ul>
                    <li><a href="/#"><i className="fab fa-facebook-f" /></a></li>
                    <li><a href="/#"><i className="fab fa-twitter" /></a></li>
                    <li><a href="/#"><i className="fab fa-linkedin-in" /></a></li>
                  </ul>
                </div> */}
              </div>
            </div>

            {/* 
            <div className="blog-next-prev">
              <ul>
                <li className="blog-prev">
                  <a href="/#"><img src="img/icon/left_arrow.png" alt="img" />Previous Post</a>
                </li>
                <li className="blog-next">
                  <a href="/#">Next Post<img src="img/icon/right_arrow.png" alt="img" /></a>
                </li>
              </ul>
            </div> */}





            {/* <div className="comment-reply-box">
                <h5 className="title">Leave a Reply</h5>
                <form className="comment-reply-form">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-grp">
                        <input type="text" placeholder="Author *" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-grp">
                        <input type="email" placeholder="Your Email *" />
                      </div>
                    </div>
                  </div>
                  <div className="form-grp">
                    <textarea name="message" placeholder="Type Comment Here..." defaultValue={""} />
                  </div>
                  <div className="form-grp checkbox-grp">
                    <input type="checkbox" id="checkbox" />
                    <label htmlFor="checkbox">Don’t show your email address</label>
                  </div>
                  <button type="submit" className="btn">Submit now</button>
                </form>
              </div> */}
          </div>




          <div className="col-lg-4">
            <aside className="blog-sidebar">
              {/* <div className="widget">
                  <h4 className="sidebar-title">Search</h4>
                  <div className="sidebar-search">
                    <form>
                      <input type="text" placeholder="Search ..." />
                      <button type="submit"><i className="fa fa-search" /></button>
                    </form>
                  </div>
                </div> */}


              {/* <div className="widget">
                  <h4 className="sidebar-title">Categories</h4>
                  <div className="sidebar-cat-list">
                  <ul>
                      <li><a href="/#">Flights <i className="fas fa-angle-double-right" /></a></li>
                      <li><a href="/#">Pet Care <i className="fas fa-angle-double-right" /></a></li>
                      <li><a href="/#">Travel <i className="fas fa-angle-double-right" /></a></li>
                      <li><a href="/#">Miscellaneous <i className="fas fa-angle-double-right" /></a></li>
                    </ul>
                  </div>
                </div> */}


              <RecentPosts />

              <DownloadGuide />


            </aside>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Blog1Post;
