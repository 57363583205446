import React, { useEffect } from 'react';
import Blog1Tags from './blog1/Blog1Tags';
import Blog1Bread from './blog1/Blog1Bread';
import Blog1Post from './blog1/Blog1Post';

function Blog1() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  });

  return (

    <main>

      <head>
          <Blog1Tags />
      </head>

      <Blog1Bread />

      <Blog1Post />
    </main>

  )
}

export default Blog1;
