import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

import img0 from '../img/gallery/0.jpg';
import img1 from '../img/gallery/1.jpg';
import img2 from '../img/gallery/2.jpg';
import img3 from '../img/gallery/3.jpg';
import img4 from '../img/gallery/4.jpg';
// import img5 from '../img/gallery/5.jpg';

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type='button' className={className} onClick={onClick}><img src="img/icon/arrow.png" alt='prev' /></button>
  );
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type='button' className={className} onClick={onClick}><img src="img/icon/arrow.png" alt='next' /></button>
  );
}

function AdoptionPuppies() {
  const settings = {

    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 1000,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
    ]
  }
  return (

    <section className="adoption-area-two pb-110" id="gallery">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">

            <div className="section-title text-center mb-65">
              <div className="section-icon"><img src="img/icon/pawprint.png" alt="" /></div>
              <h5 className="sub-title">Gallery</h5>
              <h2 className="title">A view of the experience</h2>
              {/* <p>The best overall dog DNA test is Embark Breed &amp; Health Kit (view at Chewy), which provides
                  you with a breed brwn and information most dogs</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container custom-container">
        <Slider className="row adopt-active" {...settings}>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb">
                {/* <Link to="/#"><img src="src/img/images/breed_services_img01.jpg" alt="" /></Link> */}
                <Link to="/#"><img src={img0} alt="" /></Link>
              </div>

              <div className="adopt-content">

                {/* <h3 className="title"><Link to="/#">Golden Retriever</Link></h3> */}
                {/* <p>The Golden Retriever is a medium-large gun dog that was bred.</p> */}
                {/* <Link to="/#" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb">
                {/* <Link to="/#"><img src="img/images/breed_services_img02.jpg" alt="" /></Link> */}
                <Link to="/#"><img src={img1} alt="" /></Link>
              </div>
              <div className="adopt-content">

                {/* <h3 className="title"><Link to="/#">German Sharped</Link></h3> */}
                {/* <p>The German Shepherd is a breed of medium to large-sized.</p> */}
                {/* <Link to="/#" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb">
                {/* <Link to="/#"><img src="img/images/breed_services_img03.jpg" alt="" /></Link> */}
                <Link to="/#"><img src={img2} alt="" /></Link>

              </div>
              <div className="adopt-content">

                {/* <h3 className="title"><Link to="/#">Siberian Husky</Link></h3> */}
                {/* <p>The Siberian Husky is a medium-sized working sled dog breed.</p> */}
                {/* <Link to="/#" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb">
                {/* <Link to="/#"><img src="img/images/breed_services_img04.jpg" alt="" /></Link> */}
                <Link to="/#"><img src={img3} alt="" /></Link>

              </div>
              <div className="adopt-content">

                {/* <h3 className="title"><Link to="/#">French Bulldog</Link></h3> */}
                {/* <p>French Bulldog is a breed of domestic dog, bred to be companion.</p> */}
                {/* <Link to="/#" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link> */}
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb">
                {/* <Link to="/#"><img src="img/images/breed_services_img03.jpg" alt="" /></Link> */}
                <Link to="/#"><img src={img4} alt="" /></Link>

              </div>
              <div className="adopt-content">

                {/* <h3 className="title"><Link to="/#">Siberian Husky</Link></h3> */}
                {/* <p>French Bulldog is a breed of domestic dog, bred to be companion.</p> */}
                {/* <Link to="/#" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link> */}
              </div>
            </div>
          </div>

          {/* <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb">
                <Link to="/#"><img src={img5} alt="" /></Link>
              </div>
              <div className="adopt-content"></div>
            </div>
          </div> */}


          
        </Slider>
      </div>
    </section>
  )
}

export default AdoptionPuppies;
