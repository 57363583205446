import React from 'react';
import { Link } from 'react-router-dom';
// import SearchBar from './SearchBar';
// import Catagories from './Catagories';
import RecentPosts from './RecentPosts';
import DownloadGuide from './DownloadGuide';
// import Guide from '../../downloads/Guide.pdf';

function BlogContent() {
  return (

    <section className="standard-blog-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="standard-blog-item">
              <div className="standard-blog-thumb">
                <Link to="/pet-travel-from-the-us--costa-rica"><img src="img/blog/blog_thumb01.jpg" alt="" /></Link>
              </div>
              <div className="standard-blog-content">
                <div className="blog-post-meta">
                  <ul>
                    {/* <li className="tags"><i className="flaticon-bookmark-1" />
                      <a href="/#">Pet Travel, </a>
                      <a href="/#">Regulations</a>
                    </li> */}
                    <li><i className="far fa-user" /><a href="/#">PGPA Mascot</a></li>
                    <li><i className="far fa-bell" /> Jun 9, 2022</li>
                  </ul>
                </div>
                <h2 className="title"><Link to="/pet-travel-from-the-us--costa-rica">Pet travel from the US to Costa Rica</Link></h2>
                <p>Traveling the world is always a joy, traveling to beautiful Costa Rica is better and nothing
                  beats making that journey with your beloved pet. This should be as easy as that,
                  booking a flight and going on vacation, but traveling rules, regulations and laws will
                  make traveling with your best non-human friend quite the challenge.</p>
                <Link to="/pet-travel-from-the-us--costa-rica" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
              </div>
            </div>



            <div className="standard-blog-item">
              <div className="standard-blog-thumb">
                <Link to="/your-guide-to-flying-to-costa-rica-with-your-pet"><img src="img/blog/blog_thumb02.jpg" alt="" /></Link>
              </div>
              <div className="standard-blog-content">
                <div className="blog-post-meta">
                  <ul>
                    <li><i className="far fa-user" /><a href="/#">PGPA Mascot</a></li>
                    <li><i className="far fa-bell" /> Jun 16, 2022</li>
                  </ul>
                </div>
                <h2 className="title"><Link to="/your-guide-to-flying-to-costa-rica-with-your-pet">Your Guide to Flying to Costa Rica With Your Pet</Link></h2>
                <p>As we might all know, every country is particular about what you can bring
                  through their borders. Pets are no exception to this, the moving of animals
                  comes with travel and importation rules and regulations that you should know
                  if you wish to have a pleasant visit. .</p>
                <Link to="/your-guide-to-flying-to-costa-rica-with-your-pet" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
              </div>
            </div>




            {/* <div className="standard-blog-item">
              <div className="standard-blog-thumb">
                <Link to="/pet-travel-from-the-us--costa-rica"><img src="img/blog/blog_thumb02.jpg" alt="" /></Link>
              </div>
              <div className="standard-blog-content">
                <div className="blog-post-meta">
                  <ul>
                    <li className="tags"><i className="flaticon-bookmark-1" />
                      <a href="/#">Pet Travel, </a>
                      <a href="/#">Regulations</a>
                    </li>
                    <li><i className="far fa-user" /><a href="/#">PGPA Mascot</a></li>
                    <li><i className="far fa-bell" /> Jun 9, 2022</li>
                  </ul>
                </div>
                <h2 className="title"><Link to="/pet-travel-from-the-us--costa-rica">Pet travel from the US to Costa Rica</Link></h2>
                <p>Traveling the world is always a joy, traveling to beautiful Costa Rica is better and nothing beats making that journey with your beloved pet.
                  This should be as easy as that, booking a flight and going on holiday but traveling rules, regulations and laws will make traveling with your
                  best non-human friend quite the challenge</p>
                <Link to="/pet-travel-from-the-us--costa-rica" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
              </div>
            </div>


            <div className="standard-blog-item">
              <div className="standard-blog-thumb">
                <Link to="/pet-travel-from-the-us--costa-rica"><img src="img/blog/blog_thumb03.jpg" alt="" /></Link>
              </div>
              <div className="standard-blog-content">
                <div className="blog-post-meta">
                  <ul>
                    <li className="tags"><i className="flaticon-bookmark-1" />
                      <a href="/#">Pet Travel, </a>
                      <a href="/#">Regulations</a>
                    </li>
                    <li><i className="far fa-user" /><a href="/#">PGPA Mascot</a></li>
                    <li><i className="far fa-bell" /> Jun 9, 2022</li>
                  </ul>
                </div>
                <h2 className="title"><Link to="/pet-travel-from-the-us--costa-rica">Pet travel from the US to Costa Rica</Link></h2>
                <p>Traveling the world is always a joy, traveling to beautiful Costa Rica is better and nothing beats making that journey with your beloved pet.
                  This should be as easy as that, booking a flight and going on holiday but traveling rules, regulations and laws will make traveling with your
                  best non-human friend quite the challenge</p>
                <Link to="/pet-travel-from-the-us--costa-rica" className="read-more">Read More <img src="img/icon/pawprint.png" alt="" /></Link>
              </div>
            </div> */}


          </div>
          <div className="col-lg-4 col-md-8">
            <aside className="blog-sidebar">

              {/* <SearchBar />

              <Catagories /> */}


              <RecentPosts />


              <DownloadGuide />
            </aside>
          </div>
        </div>
      </div>
    </section>

  )
}

export default BlogContent;
