import React from 'react';

function AdoptionFaq() {
  return (
    <section className="faq-area faq-two-bg" id="faqs">
      <div className="faq-two-img" />
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-xl-7 col-lg-6">
            <div className="faq-wrapper">
              <div className="section-title white-title mb-35">
                <h5 className="sub-title">FAQs</h5>
                <h4 className="title">What you need to know...</h4>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        1. How much does it cost to fly private air with my pet?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card-body">
                      The price is determined by flight routes/current fuel costs, destinations,
                      size and weight of your pet, and the overall load and number of passengers and pets traveling.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        2. Where do you fly off to? What kind of planes do you use?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                      Currently, we fly the Dallas Tx to Liberia, Costa Rica route. We use air carriers licensed under Part 135 of the Federal Aviation Regulations.
                      Typically we use a Beechcraft Hawker 800XP, Citation X, or a Citation 650.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        3: How do you reserve a spot on a flight? What are the travel requirements?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div className="card-body">

                      A 50% deposit is required to confirm.
                      Balance is due 30 days from the date of flight.
                      Payment is non-refundable unless the trip is canceled, or fuel/price adjustments exceed 8%.

                      <br />
                      <br />
                      Pet owner is responsible for:
                      <div className="adoption-list">
                        <ul>
                          <li><i className="flaticon-tick" /> Pet vaccinations as required by Costa Rica</li>
                          <li><i className="flaticon-tick" /> Passport and required personal entry documents</li>
                          <li><i className="flaticon-tick" /> Proof of return ticket for “non-residents”</li>
                        </ul>
                      </div>
                      <br />
                      We can arrange transportation from the airport to your destination anywhere
                      in Costa Rica if notified a week before the travel date.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        4. Do you process the animal travel documents?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                    <div className="card-body">

                      No, we do not, you will have to process them in advance.
                      <br />
                      <a href="https://cr.usembassy.gov/embassy/sanjose/sections-offices/animal-and-plant-health-inspection-service/want-bring-dogcat-costa-rica/" target="_blank" rel="noopener noreferrer">
                        You can get the documentation on this link
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFive">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        5. Is the immigration process the same as flying commercial? What are the baggage requirements?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                    <div className="card-body">

                      It is the same, just you use the private flights FBO services upon landing which is typically much faster.

                      <br />
                      <br />
                      You are permitted to bring up to 70Lbs per person, and a small backpack or purse.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingSix">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        6. What do I get as confirmation when I pay for the flight?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                    <div className="card-body">
                      A private jet charter flight is a different experience to flying on commercial airlines.
                      Private jet flights are ticket-free. When you book with us and complete your payment you will
                      get a payment confirmation while waiting for your travel itinerary.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingSeven">
                    <h2 className="mb-0">
                      <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        7. When can I expect my travel itinerary?
                      </button>
                    </h2>
                  </div>
                  <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                    <div className="card-body">
                      This is a shared flight and we have to allow all parties to
                      make payment for us to confirm the flight details. You will
                      receive an itinerary not less than 5 days before your flight
                      date. This will detail your route, departure time and
                      location, details of your crew and the tail number of your
                      aircraft. We also include driving directions to the
                      departure area. We will also conduct a zoom meeting to
                      review and answer questions.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-shape"><img src="img/images/faq_shape.png" alt="" /></div>
    </section>
  )
}

export default AdoptionFaq;