import React from 'react';
import Slider from 'react-slick';

function AdoptionGallery() {
	const settings = {
		dots: true,
		infinite: true,
		speed: 1000,
		autoplay: true,
		arrows: false,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		slidesToScroll: 1,
		vertical: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					speed: 1000,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					speed: 1000,
				}
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					speed: 1000,
				}
			},
		]
	}

	return (
		<section className="adoption-area-two pt-110 pb-110 breeds-services" id="about">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 order-0 order-lg-2">
						<Slider className="breeder-gallery-active" {...settings}>
							<div className="breeder-gallery-item">
								<img src="img/images/breeder_gallery01.jpg" alt="" />
							</div>
							<div className="breeder-gallery-item">
								<img src="img/images/breeder_gallery02.jpg" alt="" />
							</div>
							<div className="breeder-gallery-item">
								<img src="img/images/breeder_gallery03.jpg" alt="" />
							</div>
							<div className="breeder-gallery-item">
								<img src="img/images/breeder_gallery04.jpg" alt="" />
							</div>
						</Slider>
					</div>
					<div className="col-lg-6">
						<div className="adoption-content">
							<h5 className="sub-title">Who are we?</h5>
							<h2 className="title">A <span>private travel service</span> <br /> for you and your pet(s)</h2>
							<p>We all love our pets, and the thought of traveling with them can be daunting.
								They may have to be stuffed in an uncomfortable cage, fly in the luggage
								compartment, or travel alone with a transportation service.<br />

								Pets Go Private Air offers a travel service for you and your pet(s),
								allowing you to travel with your furry loved one by your side.
								We currently serve the Dallas, Texas to Liberia, Costa Rica route.
							</p>

							{/* <div className="adoption-list">
								<ul>
									<li><i className="flaticon-tick" /> Embark Breed &amp; Health</li>
									<li><i className="flaticon-tick" /> The domestic dog is a domesticated</li>
								</ul>
							</div> */}

						</div>
					</div>
				</div>
			</div>
			<div className="breed-services-shape"><img src="img/images/breed_services_shape01.png" alt="" /></div>
			<div className="breed-services-shape shape-two"><img src="img/images/breed_services_shape02.png" alt="" /></div>
		</section>
	)
}

export default AdoptionGallery;