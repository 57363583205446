import React, { useEffect } from 'react';
import BlogBreadcumb from '../components/blogs/BlogBreadcumb';
import BlogContent from '../components/blogs/BlogContent';

function BlogPage() {

	useEffect(() => {
		window.scrollTo({
		  top: 0,
		  left: 0,
		  behavior: "smooth"
		});
	  });

  return (

	  <main>
			<BlogBreadcumb/>
			<BlogContent/>
	  </main>
  )

}
export default BlogPage;
