import React from 'react';
import Payments from '../components/payments/Payments';
import PaymentsCrumb from '../components/payments/PaymentsCrumb';

export default function PaymentsPages() {

  return (

		  <main>
		 
		 <PaymentsCrumb />
		  <Payments />

		  </main>

  )
};