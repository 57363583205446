import React, { useEffect } from 'react';
import Blog2Bread from './blog2/Blog2Bread';
import Blog2Post from './blog2/Blog2Post';
import Blog2Tags from './blog2/Blog2Tags';

function Blog2() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  });

  return (

    <main>

      <head>
        <Blog2Tags />
      </head>

      <Blog2Bread />
      <Blog2Post />

    </main>

  )
}

export default Blog2;
