import React from 'react';

function ContactAddress() {
  return (
    <div className="col-xl-5 col-lg-6 col-md-8">
      <div className="contact-info-wrap">
        <div className="contact-img">
          <img src="img/images/contact_img.png" alt="" />
        </div>
        <div className="contact-info-list">
          <ul>
            {/* <li>
                        <div className="icon"><i className="fas fa-map-marker-alt" /></div>
                        <div className="content">
                          <p>W84 New Park Lan, New York, NY 4586 United States</p>
                        </div>
                      </li> */}
            <li>
              <div className="icon"><i className="fas fa-phone-alt" /></div>
              <div className="content">
                <p>972-362-6201</p>
              </div>
            </li>
            <li>
              <div className="icon"><i className="fas fa-envelope-open" /></div>
              <div className="content">
                <p>info@petsgoprivateair.com</p>
              </div>
            </li>
          </ul>
        </div>

        <div className="contact-social">
          <ul>
            <li><a href="https://www.facebook.com/groups/petstocostarica" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a></li>
            {/* <li><a href="/#"><i className="fab fa-twitter" /></a></li> */}
            <li><a href="https://www.instagram.com/petsgoprivate" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a></li>
            <li><a href="https://www.youtube.com/channel/UCvasmZ2hXOit8OTTKvrB8Ng" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube" /></a></li>
          </ul>
        </div>
      </div>
    </div>

  )
}

export default ContactAddress;
