import React from 'react';
import Guide from '../../downloads/Guide.pdf';
import { toast } from 'react-toastify';

const customId = "downloadNotify";
const downloadNotify = () => toast("Your download will start in a moment...",
  {
    toastId: customId, hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    position: "bottom-right",
    autoClose: 2500,
    delay: 500,
  });

function DownloadGuide() {
  return (
    <div className="widget sidebar-newsletter">
      <div className="sn-icon">
        <img src="img/icon/sn_icon.png" alt="" />
      </div>
      <div className="sn-title">
        <h4 className="title">Download Guide</h4>
        <p>Download Our Pet Travel Guide</p>
      </div>
      {/* <form className="sn-form"> */}
      <a href={Guide} download="The Pets Go Private Guide to Flying">
        {/* <input type="text" placeholder="Enter Your Email" /> */}
        <button src="downloads/Guide.pdf" download="The Pets Go Private Guide to Flying" className="btn" onClick={downloadNotify}>Download</button>
      </a>
      {/* </form> */}
    </div>
  )
}

export default DownloadGuide;
