import React from 'react';
// import { Link } from 'react-router-dom';
import DownloadGuide from '../../../components/blogs/DownloadGuide';
import RecentPosts from '../../../components/blogs/RecentPosts';

function Blog2Post() {

  return (
    <section className="blog-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="standard-blog-item mb-50">
              <div className="standard-blog-thumb">
                <img src="img/blog/blog_thumb02.jpg" alt="" />
              </div>
              <div className="standard-blog-content blog-details-content">


                <div className="blog-post-meta">
                  <ul>
                    <li><i className="far fa-user" />PGPA Mascot</li>
                    <li><i className="far fa-bell" /> Jun 16, 2022</li>
                    <li>Photo by <a href="https://unsplash.com/@tcrock18?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noopener noreferrer">Tanner 
                    Crockett</a> on <a href="https://unsplash.com/s/photos/private-jet?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noopener noreferrer">Unsplash</a></li>
                  </ul>
                </div>


                <h4 className="title">Your Guide to Flying to Costa Rica With Your Pet</h4>
                <h5>Costa Rica Pet Passport &amp; Import Regulations </h5>
                <p>As we might all know, every country is particular about what you
                  can bring through their borders. Pets are no exception to this, the
                  moving of animals comes with travel and importation rules and
                  regulations that you should know if you wish to have a
                  pleasant visit.  </p>

                {/* <blockquote>
                  "Problems are not stop signs, they are guidelines2.”
                  <footer> Robert H. Schuller.</footer>
                </blockquote> */}

                <p>If your planned destination is Costa Rica then you are in luck, we have a quick
                  guide on all you need to know about the regulations that determine how you can
                  travel with your pet to this beautiful country. Lucky for you, Costa Rica is
                  a pet-friendly country and you should have an amazing stay provided all your
                  papers are in order.  </p>

                <h3>Step 1: Check-In With Your Vet</h3>

                <p>When moving from the US to Costa Rica you need to make sure you have a
                  certificate from the vet giving your pet a clean bill of health.
                  If you cannot do this before leaving the US then make sure
                  the <a href="https://www.aphis.usda.gov/aphis/pet-travel/by-country/pettravel-costa-rica" target="_blank" rel="noopener noreferrer">
                    vet's office in Costa Rica</a> is your first stop when you land.
                </p>



                <p>When your vet has seen your pet they will issue a printed certificate which will act as a passport
                  for your pet to travel to Costa Rica. To avoid any delays or
                  unforeseen issues we advise you to make this appointment within 2
                  weeks of your departure date.   </p>

                <h5>Make sure your certificate highlights the following:</h5>
                {/* <p>
                  There are a few rules and regulations that you need to know and follow if you want your furry friends to enter Costa Rica.
                </p> */}
                <div className="blog-details-list">
                  <ul>
                    <li>Rabies vaccination.</li>
                    <li>Distemper, parvovirus, hepatitis and leptospirosis vaccination.</li>
                    <li>Internal and External parasite treatment within 15 days. </li>
                  </ul>
                </div>


                <h5>Requirements for CATS entering Costa Rica:</h5>
                <div className="blog-details-list">
                  <ul>
                    <li>Your cat must have a Rabies dosage that is at least 30 days old but less than 3 years.</li>
                    <li>Your cat’s Ecto and Endo worming treatment must be given within 15 days of the trip.</li>
                    <li>Your cat’s FVRCP (Feline Viral Rhinotracheitis, Calicivirus, Panleukopenia and Feline Leukemia) must be current.</li>
                  </ul>
                </div>
                <p>Make sure your pet is microchipped before entering Costa Rica. This will help you keep track of your pet. </p>

                <h5>USDA Accredited Veterinarians</h5>
                <p>
                  Once your vet has completed your certificate you need to then visit a USDA veterinarian for endorsement.
                  This is to make the certificate internationally recognised. <h6>Costa Rica requires an international health
                    certificate signed by a veterinarian and then endorsed by APHIS (Animal and Plant Health Inspection Service). </h6>
                  This is made easier if your vet is USDA accredited as this means they can do it all in one go.
                </p>





                <h3>Step 2: Meet the Kennel Requirements</h3>

                <p>When you have cleared the vet requirement you now need to make sure your pet has the right
                  boarding equipment. A kennel is a must when boarding your pet.
                  Pets can have a mind of their own, which could be dangerous in an airport.</p>

                <p>The <a href="https://www.tsa.gov/" target="_blank" rel="noopener noreferrer">TSA</a> checks all kennels to make sure they meet the requirements
                  depending on your pet. If your kennel falls short of the requirements then you can be denied boarding. </p>

                <p>You can consult with the airline you are flying about the kennel requirements for your pet so you can travel with your pets without any issues.</p>

                <h3>Step 3: How to Handle the Airport</h3>
                <p>Now you have your paperwork and your kennel ready. Make sure you are at the airport early so you can have your pet and
                  kennel weighed. TSA will wipe the Kennel down and instruct you on how you and your pet will be boarding. </p>

                <h5>Arriving in Costa Rica</h5>

                <p>When you land in Costa Rica you will go through the usual immigration procedures, your pet usually meets you at the baggage
                  claim. You must then find a SENASA customs official to clear your pet. This usually involves presenting your pet's papers
                  and if everything checks out they will be stamped and you will be free to move about with your pet. </p>

                <h5>Have A Great Time In Beautiful Costa Rica</h5>
                <p>
                Costa Rica is one of the best places you and your pet can visit. As Pets Go Private, 
                we aim to make sure this trip is made memorable by giving you and your pet the best 
                first class treatment from departure to landing. You can fly easy knowing you are in 
                the safest and most capable hands with our cabin and crew. 

                </p>
               
                <div className="blog-line" data-background="img/blog/blog_item_line.png" />

              </div>


            </div>
            <div className="avatar-post mt-50 mb-50">
              <div className="post-avatar-img">
                <img src="img/blog/mascot.png" alt="img" />
              </div>
              <div className="post-avatar-content">
                <h5>By PGPA Mascot</h5>
                <p>Pet flights specialist focused on the comfort and safety of your beloved pets during travel.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <aside className="blog-sidebar">
            
              <RecentPosts />

              <DownloadGuide />

            </aside>
          </div>
        </div>
      </div>
    </section >

  )
}

export default Blog2Post;
