import React from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SERVICE_ID, TEMP_ID, USER_ID } from '../constants/MailConst';

const customId = "estimateNotify";
const estimateNotify = () => toast("Thank you for your enquiry. Our agents will get in touch with you shortly with an estimate!", { toastId: customId });

function priceEstimate(e) {

    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMP_ID, e.target, USER_ID)
        // emailjs.sendForm('service_p6foln6', 'template_5wmsuhp', e.target, 'bPaHIR_EkUWZNksYl')
        .then((result) => {
            console.log(result.text);
            estimateNotify();
            setTimeout(() => { window.location.reload() }, 5000);
        }, (error) => {
            console.log(error.text);
        });
    e.target.reset();
}

function GetEstimate() {

    // const [selected, setSelected] = React.useState("");

    // const changeSelectOptionHandler = (event) => {
    //     setSelected(event.target.value);
    // };

    // const dogs = ["Golden Retriever", "Labrador Retriever", "French Bulldog", "Baeagle", "Poodle", "Pug", "Pomeranian", "German Shepherd", "Other"];
    // const cats = ["Siamese", "Persian", "Bobcat", "Ragdoll", "Abyssinian", "Other"];
    // const birds = ["Parrot", "Penguin", "Other"];

    // let type = null;

    // let options = null;

    // if (selected === "Bird") {
    //     type = birds;
    // } else 
    
    // if (selected === "Dog") {
    //     type = dogs;
    // } else if (selected === "Cat") {
    //     type = cats;
    // }

    // if (type) {
    //     options = type.map((el) => <option key={el}>{el}</option>);
    // }

    return (
        <section className="adoption-area">
            <div className="container">
                <div className="row align-items-center align-items-xl-end justify-content-center">
                    <div className="col-xl-7 col-lg-6 col-md-10 order-0 order-lg-2">
                        <div className="widget breeder-widget">
                            <div className="breeder-widget-title mb-20">
                                <h6 className="title">Send Us A Message</h6>
                            </div>

                            <form className="sidebar-find-pets" onSubmit={priceEstimate}>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-grp">
                                            <i className="far fa-user" />
                                            <input type="text" placeholder="First Name" name="name" required />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-grp">
                                            <i className="far fa-user" />
                                            <input type="text" placeholder="Last Name" name="lastname" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-grp">
                                            <i className="fa fa-at" />
                                            <input type="email" placeholder="Email" name="email" required />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-grp">
                                            <i className="fa fa-phone" />
                                            <input type="tel" placeholder="Phone Number" name="phone" required />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-grp">
                                            <i className="fa fa-user" />
                                            <select name="passengers" className="selected" placeholder="Number of People" required>
                                                <option value="Not Specified">Number of People</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5+">5+</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-grp">
                                        <i className="fa fa-paw" />
                                            <select name="infants" className="selected" placeholder="Number of Pets" required>
                                                <option value="Not Specified">Number of Pets</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5+">5+</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {/*
                                <div className="breeder-widget-title mb-20">
                                    <h6 className="title">Travel Details</h6>
                                </div>

                                 <div className="row">
                                    <div className="col-6">
                                        <div className="form-grp">
                                            <i className="fa fa-plane-departure" />
                                            <select name="from" className="selected" placeholder="Departing From" required>
                                                <option value="not_selected">Departing from</option>
                                                <option value="dallas_kads">Addison Airport, Dallas (KADS)</option>
                                                <option value="sanjose_sjo">Juan Santamaría International Airport (SJO)</option>
                                                <option value="costa_lir">Daniel Oduber Quirós International Airport (LIR)</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-grp">
                                            <i className="fa fa-plane-arrival" />
                                            <select name="to" className="selected" placeholder="Arriving To" required>
                                                <option value="not_selected">Going to</option>
                                                <option value="dallas_kads">Addison Airport, Dallas (KADS)</option>
                                                <option value="sanjose_sjo">Juan Santamaría International Airport (SJO)</option>
                                                <option value="costa_lir">Daniel Oduber Quirós International Airport (LIR)</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-grp">
                                    <i className="far fa-calendar-alt" />
                                    <input type="date"
                                        defaultValue="2020-01-01" />
                                </div>

                                <div className="breeder-widget-title mb-20">
                                    <h6 className="title">Pet(s) Details</h6>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-grp">
                                            <i className="fa fa-paw" />
                                            <select name="pet" className="selected" required onChange={changeSelectOptionHandler}>
                                                <option>Choose...</option>
                                                <option>Dog</option>
                                                <option>Cat</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-grp">
                                            <i className="fa fa-genderless" />
                                            <select name="breed" className="selected">
                                                {
                                                    options
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-grp">
                                            <i className="fa fa-weight" />
                                            <input type="text" placeholder="Weight (lbs)" name="weight" required />
                                        </div>
                                    </div>
                                </div> */}
                                
                                <div className="form-grp">
                                    {/* <i className="fa fa-comment" /> */}
                                    {/* <input type="text" placeholder="Additional Information you may want us to know..." name="other" /> */}
                                    <textarea style={{minHeight: '100px', minWidth: '100%', padding: "15px 20px", border: "1px solid #e1e1e1", borderRadius: "6px"}} placeholder="Additional Information you may want us to know..." name="other"/>
                                </div>

                                <button className="btn"
                                >Send Message</button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="adoption-shape"><img src="img/images/adoption_shape.png" alt="" /></div>

        </section>
    )
}

export default GetEstimate;