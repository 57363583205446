import React from 'react';
import {Link} from 'react-router-dom';


function Blog2Bread() {
  return (
	    <section className="breadcrumb-area breadcrumb-bg" style={{ backgroundImage:'url("img/bg/breadcrumb_bg.jpg")'}}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content">
                <h2 className="title">Your Guide to Flying to Costa Rica With Your Pet. </h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Your Guide to Flying to Costa Rica With Your Pet. </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

  )
}
export default Blog2Bread;
