import React from 'react';

import PaymentInfo from '../../downloads/PaymentInfo.pdf';
import { toast } from 'react-toastify';

const customId = "dLoading";
const dLoading = () => toast("Your download will start in a moment...",
    {
        toastId: customId, hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        position: "bottom-center",
        autoClose: 2500,
        delay: 500,
    });

export default function Payments() {

    const DownloadPayInfo = () => {
        return (

            <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-9">
                    <div className="section-title text-center mb-65">
                        <div className="section-icon"><img src="img/icon/pawprint.png" alt="" /></div>
                        <p>..you can also download this information :) </p>
                        <br />

                        <a href={PaymentInfo} download="Pets Go Private Payment Information">
                            <button className="btn" onClick={dLoading}>Download</button>
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    return (

        <section className="adoption-shop-area breeder-bg">
            <div className="container">
                <div className="row align-items-center justify-content-center">

                    <div className="col-xl-8 col-lg-9 col-md-12 col-sm-9">
                        <div className="row no-gutters justify-content-center">

                            <div className="col-md-6">
                                <div className="adoption-content" style={{ backgroundPosition: 'center', backgroundSize: 'cover', padding: '53px 50px', borderRadius: '4px', minHeight: '100%' }}>
                                    <h5 className="sub-title">Bank / Wire</h5>
                                    <h2 className="title">Payments Information</h2>
                                    <p>For any payments to be made, please utilize the information shown here.</p>
                                    <p> For inquiries, please call <br />
                                        <a href="tel:9723626201">972-362-6201</a>,
                                        or send us an email on <a href="mailto:info@petsgoprivateair.com">info@petsgoprivateair.com</a>.</p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <img src="img/images/cashy.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-9 col-md-12 col-sm-9">

                        <div className="contact-info-wrap">
                            <div className="contact-info-list">
                                <ul>
                                    <li>
                                        <div className="icon">
                                            <i className="fas fa-university" />
                                        </div>
                                        <div className="content">
                                            <p>J.P. Morgan Chase Bank, <br />
                                                2370 FM 407, <br />
                                                Highland Village,  <br />
                                                Texas 75077
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon"><i className="fas fa-indent" /></div>
                                        <div className="content">
                                            <p>Wiring Information <br />
                                                Routing #111000614 <br />
                                                Account # 855632789 <br />
                                                Swift Code: CHASUS33
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon"><i className="fas fa-envelope-open" /></div>
                                        <div className="content">
                                            <p>Pets Go Private,LLC, <br />
                                                440 US Hwy 377, <br />
                                                Suite 114, <br />
                                                Argyle, <br />
                                                Texas 76226
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {DownloadPayInfo()}

            </div>
        </section>
    )
}
