import Helmet from 'react-helmet';

export default function Blog1Tags() {
  return (

    <Helmet
      // htmlAttributes={{
      //   lang,
      // }}
      title={"Pet Travel From the US to Costa Rica"}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          property: `og:title`,
          content: 'Pet Travel From the US to Costa Rica'
        },
        {
          property: `og:description`,
          content: 'Traveling the world with your pet should be simplified, but traveling rules & regulations make it a challenge. Read this to make your next trip easier',
        },
        {
          property: `og:url`,
          content: `https://www.petsgoprivateair.com/pet-travel-from-the-us--costa-rica`,
        },
        {
          property: `og:image`,
          content: `./blog_thumb01.jpg`,
        },
        {
          property: `og:type`,
          content: `article`,
        },
        {
          name: `og:site_name`,
          content: "Pets Go Private Air",
        },
        {
          name: `twitter:creator`,
          content: "PGPA Mascot",
        },
        {
          name: `twitter:title`,
          content: "Pet Travel From the US to Costa Rica",
        },
        {
          name: `twitter:image`,
          content: `./blog_thumb01.jpg`,
        },
        {
          name: `twitter:description`,
          content: 'Traveling the world with your pet should be simplified, but traveling rules & regulations make it a challenge. Read this to make your next trip easier',
        },
      ]
        // .concat(meta)
      }
    />
  );
};