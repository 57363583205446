import React from "react";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Footer() {
  return (
    <footer>
      <div className="footer-top-area footer-bg">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-3">
              <div className="footer-widget">
                <div className="f-logo">
                  <HashLink to="/#home" smooth><img src="img/logo/logo.png" alt="" /></HashLink>
                </div>
                <div className="footer-text">
                  <p>Fly Private Air with Pets.</p>
                </div>
                {/* <div className="footer-contact">
                  <div className="icon"><i className="fas fa-headphones" /></div>
                  <div className="content">
                    <h4 className="title"><a href="tel:9723626201">972-362-6201</a></h4>
                    <span>Call Now</span>
                  </div>
                </div> */}

                {/* <div className="footer-social">
                  <ul>
                    <li><a href="https://www.facebook.com/groups/petstocostarica" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a></li>
                    <li><a href="https://www.instagram.com/petsgoprivate" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a></li>
                  </ul>
                </div> */}
              </div>
            </div>

            <div className="col-2">
              <div className="footer-widget">
                <Link to="/contact"><h4 className="fw-title">Call Us</h4></Link>
                <div className="footer-contact">
                  <div className="icon"><i className="fas fa-phone" /></div>
                  {/* <div className="content"> */}
                  <h5 className="title"><a href="tel:9723626201">972-362-6201</a></h5>
                  {/* <p>
                    <span>Call Now</span>
                    </p> */}
                  {/* </div> */}
                </div>
              </div>
            </div>


            {/* <div className="col-2">
            <div className="footer-widget">
              <h4 className="fw-title">Our Services</h4>
              <div className="fw-link">
                <ul>
                  <li><Link to="/breeder">Our Breeder</Link></li>
                  <li><Link to="/adoption">Our Adoption</Link></li>
                  <li><Link to="/contact">Editorial Policy</Link></li>
                  <li><Link to="/contact">Return Policy</Link></li>
                  <li><Link to="/contact">Grievance Policy</Link></li>
                  <li><Link to="/contact">Redressal Policy</Link></li>
                  <li><Link to="/contact">Contact Us</Link></li>
                </ul>
              </div>
            </div>
          </div> */}
            <div className="col-3">
              <div className="footer-widget">
                <h4 className="fw-title">Follow Us On Socials</h4>
                <div className="header-action d-none d-md-block">
                  <ul>
                    <li><a href="https://www.facebook.com/groups/petstocostarica" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a></li>
                    <li><a href="https://www.instagram.com/petsgoprivate" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a></li>
                    <li><a href="https://www.youtube.com/channel/UCvasmZ2hXOit8OTTKvrB8Ng" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-shape"><img src="img/images/footer_shape01.png" alt="" /></div>
        <div className="footer-shape shape-two"><img src="img/images/footer_shape02.png" alt="" /></div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="copyright-text">
                <p>Copyright © 2022 petsgoprivate. All Rights Reserved.</p>
              </div>
            </div>
            <div className="col-md-4 d-none d-md-block">
              <div className="footer-lang">
                <div className="dropdown">
                  {/* <button className="dropdown-toggle" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="img/icon/united-states.png" alt="" /> English
                </button> */}

                  {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                  <Link className="dropdown-item" to="/"><img src="img/icon/russia.png" alt="" />Russia</Link>
                  <Link className="dropdown-item" to="/"><img src="img/icon//thailand.png" alt="" />Thailand</Link>
                  <Link className="dropdown-item" to="/"><img src="img/icon/india.png" alt="" />India</Link>
                </div> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

  )
}

export default Footer;