import React from 'react';
// import Slider from 'react-slick';
// import {Link} from 'react-router-dom';

// function PrevArrow(props){
//   const {className,onClick} = props;
//   return(
//     <button type='button' className ={ className } onClick={ onClick }><img src="img/icon/arrow.png" alt='prev' /></button>
//   );
// }
// function NextArrow(props){
//   const {className,onClick} = props;
//   return(
//     <button type='button' className ={ className } onClick={ onClick }><img src="img/icon/arrow.png" alt='next' /></button>
//   );
// }

function Intro() {


  //   const settings = {

  //   dots: false,
  // 	infinite: true,
  // 	speed: 1000,
  // 	autoplay: false,
  // 	arrows: true,
  // 	autoplaySpeed: 3000,
  // 	prevArrow: <PrevArrow/>,
  // 	nextArrow: <NextArrow/>,
  // 	slidesToShow: 4,
  // 	slidesToScroll: 1,
  // 	responsive: [
  // 		{
  // 			breakpoint: 1200,
  // 			settings: {
  // 				slidesToShow: 3,
  // 				slidesToScroll: 1,
  // 				infinite: true,
  // 			}
  // 		},
  // 		{
  // 			breakpoint: 992,
  // 			settings: {
  // 				slidesToShow: 2,
  // 				slidesToScroll: 1,
  // 				speed: 1000,
  // 			}
  // 		},
  // 		{
  // 			breakpoint: 767,
  // 			settings: {
  // 				slidesToShow: 2,
  // 				slidesToScroll: 1,
  // 				arrows: false,
  // 				speed: 1000,
  // 			}
  // 		},
  // 		{
  // 			breakpoint: 575,
  // 			settings: {
  // 				slidesToShow: 1,
  // 				slidesToScroll: 1,
  // 				arrows: false,
  // 				speed: 1000,
  // 			}
  // 		},
  // 	]
  //   }

  return (

    <section className="adoption-area-two pt-110 pb-110 breeds-services" id="vision">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon"><img src="img/icon/pawprint.png" alt="" /></div>
              <h5 className="sub-title">Why we do it...</h5>
              <h2 className="title">Pet-friendly travel</h2>
              <p>We have always loved traveling to and from Costa Rica with our pet dog,
                CHIP, but owing to flying restrictions imposed by the COVID-19 Pandemic,
                most pet owners have not been able to fly with their pets. We have devised
                a way to make traveling easier for you; unlimited travel with your pet on
                a private jet! You can now fly hassle free with your pet in the same plane. </p>
            </div>
          </div>
        </div>
      </div>
      <div className="breed-services-shape"><img src="img/images/breed_services_shape01.png" alt="" /></div>
      <div className="breed-services-shape shape-two"><img src="img/images/breed_services_shape02.png" alt="" /></div>
    </section>
  )
}

export default Intro;
